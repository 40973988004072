//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import XModal from "@/components/XModal";
export default {
  name: "planChoose",
  props: {
    teamId: {
      //是否为单选
      type: Number,
      default: 0,
    },
    isRadio: {
      //是否为单选
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "medium",
    },
    custom: {
      //自定义按钮
      type: Boolean,
      default: false,
    },
    isShow: {
      //选中是否显示
      type: Boolean,
      default: true,
    },
    showLength: {
      //显示固定长度个数
      type: Number,
      default: 0,
    },
    isShowLength: {
      //显示固定长度
      type: Boolean,
      default: false,
    },
    modalName: {
      type: String,
      default: "choosePlan",
    },
    //默认项目id
    defaultEid: {
      type: Number | String,
      default: null,
    },
  },
  components: {
    XModal,
  },
  data() {
    return {
      activePlanType: 1,
      planListCopy: [],
      searchValue: "",
      loading: false,
      selPlanList: [], //获得所选任务
      planList: [],
      chooseTypeList: [
        {
          type: 1,
          value: "我参与的计划",
        },
        {
          type: 2,
          value: "我创建的计划",
        },
      ],

      activePlan: "我参与的计划", //任务选择

      //查询参数
      queryType: 1, //计划查询类型id
      keywords: "",

      isMoblie: false, //是否移动端
      tabIndex: 1, //移动端页面切换
      showDetail: false, //移动端详细搜索
      width: "",
      props: {
        multiple: true,
      },
    };
  },
  watch: {
    queryType() {
      this.getPlans();
      if (this.isRadio) {
        this.selPlanList = [];
      }
    },
    activePlanType() {
      this.getPlans();
      if (this.isRadio) {
        this.selPlanList = [];
      }
    },

    width() {
      if (this.width < 991) {
        this.isMoblie = true;
      } else {
        this.isMoblie = false;
      }
    },
  },
  methods: {
    handleClose(id) {
      this.selPlanList = this.selPlanList.filter((item) => {
        return item.id !== id;
      });
      this.$emit("getPlanList", this.selPlanList);
    },
    getPlans() {
      //默认获取全部任务
      this.loading = true;
      let pind = null;
      if (this.activePlanType == 1) {
        pind = null;
      } else {
        pind = this.defaultEid;
      }
      let params = {
        teamId: this.teamId,
        viewType: this.activePlanType,
        eId: pind,
        type: this.queryType,
        searchText: this.keywords,
      };
      this.$http
        .post("/Task/TaskPlan/GetTaskPlan.ashx", params)
        .then((res) => {
          if (res.res == 0) {
            this.planList = res.data;
            this.planListCopy = JSON.parse(JSON.stringify(res.data));
          }
        })
        .finally(() => (this.loading = false));
    },

    toggleType(item, type) {
      //切换任务类型
      // this.tabIndex++;
      if (type == 1) {
        this.activePlanType = item;
      } else {
        this.activePlan = item.value;
        this.queryType = item.type;
      }
    },

    getSelPlanList() {
      //是否单选
      if (this.isRadio) {
        if (this.selPlanList.length > 1) {
          this.selPlanList = this.selPlanList.splice(-1, 1);
        }
      }
    },
    getCheckList() {
      if (this.tabIndex == 1 && this.isMoblie) {
        this.tabIndex++;
      } else {
        //获取所选任务
        this.$modal.hide(this.modalName);
        this.$emit("getPlanList", this.selPlanList);
        this.planList = [];
      }
    },
    back() {
      //移动端返回
      if (this.tabIndex == 1) {
        return;
      }
      this.tabIndex--;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen() {
      this.tabIndex = 1;
      this.activePlanType = 1;
      this.planList = [];
      this.selPlanList = [];
      this.width = document.documentElement.clientWidth;
      this.getPlans();
    },
    closed() {
      this.planList = [];
    },
    search() {
      this.planList = [];
      if (this.isRadio) {
        this.selPlanList = [];
      }
      this.getPlans();
    },
  },

  mounted() {
    this.width = document.documentElement.clientWidth;
    window.onresize = () => {
      this.width = document.documentElement.clientWidth;
    };
  },
};
